<template>
  <div class="main">
    <div class="modal">
      <div class="modal-left">
        <h2 class="title">Регистрация</h2>
      </div>
      <div class="registration-form modal-right">
        <div class="bonusCont">
          <p class="bonus-title">Приветственные бонусы за регистрацию</p>
          <div class="bonusBlock">
            <div class="bonus-item">
              <p>10%</p>
              <p>КЭШБЭК</p>
            </div>
            <div class="bonus-item">
              <p>100%</p>
              <p>НА ДЕПОЗИТ</p>
            </div>
            <div class="bonus-item">
              <p>100</p>
              <p>ФРИСПИНОВ</p>
            </div>
          </div>
        </div>
        <form
          action="#"
          id="registrationForm"
          :class="{'form_disabled': isRequestSending}"
          @submit.prevent="submitForm"
        >
          <div class="form-group" style="width: 100%">
            <label for="phone">Телефон</label>
            <input type="tel" id="phone" required v-model="phone" />
          </div>

          <div class="form-group promo-code">
            <label for="password">Пароль</label>
            <input type="text" id="password" required v-model="password" />
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="currency">Валюта</label>
              <select name="currency" id="currency" v-model="currency">
                <option value="RUB" selected>RUB</option>
                <option value="UAH">UAH</option>
              </select>
            </div>
            <div class="form-group">
              <label for="currency">Помокод</label>
              <input type="text" id="promo" v-model="promocode" />
            </div>
          </div>
          <div class="form-check">
            <label class="perm-check">
              <input type="checkbox" id="check" checked required />
              <p>
                Я прочитал и согласен с <a href="$">Правилами и условиями</a>
              </p>
            </label>
          </div>
          <button type="submit" class="submit-btn">Зарегистрироваться</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import axios from 'axios'

export default {
  name: 'landing-page',
  data () {
    return {
      phone: '+7',
      password: '',
      country: 'RU',
      currency: 'RUB',
      promocode: 'SKUF',
      checkbox: false,
      isRequestSending: false
    }
  },
  methods: {
    submitForm () {
      if (this.isRequestSending) {
        return
      }

      this.isRequestSending = true

      const data = {
        user: {
          country: this.country,
          currency: this.currency,

          bonus_code: this.promocode,
          captcha: '',
          parent_refcode: '',
          profile_attributes: {
            age_acceptance: true,
            terms_acceptance: true,
            game_currency: this.currency,
            receive_newsletters: false,
            receive_promos: false,
            last_name: '',
            first_name: '',
            receive_sms_promos: false,
            nickname: '',
            currency: this.currency,
            language: 'ru',
            country: this.country
          },
          phone: {
            country: this.country,
            number: `${this.phone}`
          },
          password: this.password,
          password_confirmation: this.password
        },
        lang: 'ru'
      }

      console.log(data)

      this.fetchData(data)
    },
    fetchData (data) {
      axios.post('/script.php', data, {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
        .then((response) => {
          this.isRequestSending = false

          if (response.status === 200 || response.statusText === 'OK') {
            if (response.data) {
              const { token, url } = response.data

              if (token && token.length) {
                useToast().success('Успешная регистрация! Перенаправление...')
                document.location.href = url

                return
              }
            }

            useToast().error('Ошибка при регистрации')

            return
          }

          console.log('error', response)
          useToast().error('Ошибка при отправке запроса')
        })
        .catch((error) => {
          this.isRequestSending = false
          console.log('request error', error)
          useToast().error('Ошибка при отправке запроса')
        })
    }
  }
}
</script>

<style src="./style.scss" lang="scss"></style>
